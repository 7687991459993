<template>
  <v-card class="pa-10">
    <Loader v-if="loading"/>
    <template v-else>
      <v-row no-gutters>
        <v-col
          cols="12"
          md="8"
        >
          <v-form
            ref="addIssueForm"
            v-model="valid"
            autocomplete="chrome-off"
          >
            <v-row no-gutters>
              <v-col cols="12" class="px-7">
                <label for="title">{{ $t('saveIssue.title') }}</label>
                <v-text-field
                  v-model="form.title"
                  id="title"
                  outlined
                  required
                  validate-on-blur
                  dense
                  single-line
                  :rules="required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="px-7">
                <label for="categories">{{ $t('saveIssue.categories') }}</label>
                <v-select
                  v-model="form.categoryDictionaryId"
                  id="categories"
                  :items="categoriesIssue"
                  item-text="name"
                  item-value="dictionaryId"
                  outlined
                  required
                  validate-on-blur
                  dense
                  single-line
                  :rules="required"
                ></v-select>
              </v-col>
              <v-col cols="12" class="my-4">
                <v-card>
                  <v-card-title class="text-h5">
                    {{ $t('saveIssue.orderingTitle') }}
                  </v-card-title>
                  <v-card-text class="h-100 d-flex align-end">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <label for="companyName">{{ $t('saveIssue.companyName') }}</label>
                        <v-autocomplete
                          v-model="selectedCompanyOrdering"
                          :items="companies"
                          :loading="companyOrderingLoading"
                          :search-input.sync="companyOrderingSearch"
                          hide-no-data
                          hide-selected
                          item-text="name"
                          id="companyName"
                          return-object
                          outlined
                          required
                          validate-on-blur
                          dense
                          single-line
                          :rules="required"
                          @update:search-input="selectedCompanyOrderingSearchInput"
                          @input="selectedCompanyOrderingInput"
                        />
                      </v-col>
                      <v-col cols="12">
                        <label for="companyId">{{ $t('saveIssue.companyId') }}</label>
                        <v-text-field
                          v-model="form.orderingCompany.displayId"
                          id="companyId"
                          outlined
                          required
                          validate-on-blur
                          dense
                          single-line
                          :rules="required"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="d-flex align-center">
                        <div class="w-100 employee-autocomplete">
                          <label for="employee">{{ $t('saveIssue.employee') }}</label>
                          <v-autocomplete
                            v-model="form.orderingCompany.user"
                            :items="employeesOrdering"
                            id="employee"
                            hide-no-data
                            hide-selected
                            :item-text="displayFullName"
                            item-value="id"
                            outlined
                            required
                            validate-on-blur
                            dense
                            single-line
                            return-object
                            :rules="required"
                          ></v-autocomplete>
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <label for="submitDate">{{ $t('saveIssue.submitDate') }}</label>
                            <v-text-field
                              v-model="form.orderingCompany.dateOfIssueRegistration"
                              id="submitDate"
                              append-icon="mdi-calendar"
                              readonly
                              dense
                              outlined
                              single-line
                              :rules="required"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="form.orderingCompany.dateOfIssueRegistration"
                            first-day-of-week="1"
                            @input="menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" class="my-4">
                <v-card>
                  <v-card-title class="text-h5">
                    {{ $t('saveIssue.descriptionTitle') }}
                  </v-card-title>
                  <v-card-text class="h-100 d-flex align-end">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <label for="addDescription">{{ $t('saveIssue.addDescription') }}</label>
                        <v-textarea
                          v-model="form.details.description"
                          id="addDescription"
                          outlined
                          required
                          validate-on-blur
                          dense
                          single-line
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12">
                        <v-menu
                          v-model="menu2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <label for="executedOrderDate">{{ $t('saveIssue.executedOrderDate') }}</label>
                            <v-text-field
                              v-model="form.details.dateOfOrderExecution"
                              id="executedOrderDate"
                              append-icon="mdi-calendar"
                              readonly
                              dense
                              outlined
                              single-line
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="form.details.dateOfOrderExecution"
                            first-day-of-week="1"
                            @input="menu2 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12">
                        <label for="orderId">{{ $t('saveIssue.orderId') }}</label>
                        <v-text-field
                          v-model="form.details.orderId"
                          id="orderId"
                          outlined
                          required
                          validate-on-blur
                          dense
                          single-line
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <label for="conversation">{{ $t('saveIssue.conversation') }}</label>
                        <v-select
                          v-model="form.details.chatContext"
                          id="conversation"
                          :items="chatContextTypes"
                          item-value="id"
                          item-text="description"
                          outlined
                          required
                          validate-on-blur
                          dense
                          single-line
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <UploadFileButton
                          :items="form.details.documents"
                          :label="$t('saveIssue.addFile')"
                          @remove="uploadFileButtonRemove"
                          @input="uploadFileButtonInput"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" class="my-4">
                <v-card>
                  <v-card-title class="text-h5">
                    {{ $t('saveIssue.relatedCompanyTitle') }}
                  </v-card-title>
                  <v-card-text class="h-100 d-flex align-end">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <label for="selectedLinkedCompany">{{ $t('saveIssue.companyName') }}</label>
                        <v-autocomplete
                          v-model="selectedLinkedCompany"
                          :items="linkedCompanies"
                          :loading="companyLinkedLoading"
                          :search-input.sync="companyLinkedSearch"
                          hide-no-data
                          hide-selected
                          item-text="name"
                          id="selectedLinkedCompany"
                          return-object
                          outlined
                          required
                          validate-on-blur
                          dense
                          single-line
                          @update:search-input="selectedCompanyLinkedSearchInput"
                          @input="selectedCompanyLinkedInput"
                        />
                      </v-col>
                      <v-col cols="12">
                        <label for="linkedCompanyId">{{ $t('saveIssue.companyId') }}</label>
                        <v-text-field
                          v-model="form.linkedCompany.displayId"
                          id="linkedCompanyId"
                          outlined
                          required
                          validate-on-blur
                          dense
                          single-line
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <label for="linkedCompanyUserId">{{ $t('saveIssue.employee') }}</label>
                        <v-autocomplete
                          v-model="form.linkedCompany.user"
                          :items="employeesLinked"
                          id="linkedCompanyUserId"
                          hide-no-data
                          hide-selected
                          :item-text="displayFullName"
                          item-value="id"
                          outlined
                          required
                          validate-on-blur
                          dense
                          return-object
                          single-line
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-row no-gutters class="mt-6">
            <v-col cols="12" class="w-100 d-flex justify-end">
              <v-btn
                color="primary"
                class="mr-2"
                :disabled="saveDraftLoading"
                :loading="saveDraftLoading"
                @click="saveAsDraft"
              >
                {{ $t("saveIssue.saveDraft") }}
              </v-btn>
              <v-btn
                color="primary"
                class="mr-2"
                :disabled="saveLoading"
                :loading="saveLoading"
                @click="submit"
              >
                {{ $t("saveIssue.save") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-8">
            <v-col cols="12" offset-md="4" md="8">
              <label for="bokAssign">{{ $t('saveIssue.bokAssign') }}</label>
              <v-select
                v-model="form.assignedTo"
                id="bokAssign"
                :items="usersToAssign"
                item-text="name"
                item-value="id"
                outlined
                required
                validate-on-blur
                dense
                single-line
                return-object
              ></v-select>
            </v-col>
            <v-col cols="12" offset-md="4" md="8">
              <label for="bokStatus">{{ $t('saveIssue.bokStatus') }}</label>
              <v-select
                v-model="form.state"
                id="bokStatus"
                :items="issueState"
                item-value="id"
                item-text="description"
                outlined
                required
                validate-on-blur
                dense
                single-line
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </v-card>
</template>

<script>
import UploadFileButton from '@/components/forms/UploadFileButton'
import Service from '@/services'
import moment from 'moment'
import { cloneObj, equalsObj } from '@/utils/helpers'
import Loader from '@/components/Loader'
import Issue from '@/mixins/Issue'

export default {
  name: 'Save',
  components: {
    UploadFileButton,
    Loader
  },
  mixins: [Issue],
  data () {
    return {
      saveDraftLoading: false,
      lastVersion: null,
      intervalSaveAsDraft: null,
      draftId: null
    }
  },
  computed: {
    draftMode () {
      return !!this.$route.params.id || !!this.draftId
    }
  },
  async created () {
    this.loading = true
    await this.fetchData()
    if (this.draftMode) {
      this.draftId = this.$route.params.id
      await this.loadDetails()
    } else {
      this.lastVersion = cloneObj(this.form)
      this.intervalSaveAsDraft = setInterval(this.autoSaveAsDraft, 5000)
    }
    this.loading = false
  },
  beforeDestroy () {
    clearInterval(this.intervalSaveAsDraft)
  },
  methods: {
    async fetchData () {
      try {
        const { data: categoriesIssue } = await Service.get('Issue/GetIssueCategories')
        this.categoriesIssue = categoriesIssue
        const { data: usersToAssign } = await Service.get('Issue/GetUsersToAssign')
        this.usersToAssign = usersToAssign
      } catch (e) {
      }
    },
    async loadDetails () {
      try {
        const { data } = await Service.get('Issue/GetGeneralIssueDraftDetails', {
          issueDraftId: this.$route.params.id
        })

        this.form = {
          ...data
        }

        const createdByUser = this.getUser(this.form.createdBy)
        if (createdByUser) {
          this.form.createdByUser = createdByUser
        } else {
          this.form.createdByUser = {}
        }

        if (this.form.orderingCompany.dateOfIssueRegistration) {
          this.form.orderingCompany.dateOfIssueRegistration = moment(this.form.orderingCompany.dateOfIssueRegistration).format('YYYY-MM-DD')
        }

        if (this.form.orderingCompany.name && this.form.orderingCompany.name.length > 0) {
          await this.selectedCompanyOrderingSearchInput(this.form.orderingCompany.name)
          await this.selectedCompanyOrderingInput(this.companies[0])
          this.selectedCompanyOrdering = this.companies[0]
        }

        if (this.form.linkedCompany.name && this.form.linkedCompany.name.length > 0) {
          await this.selectedCompanyLinkedSearchInput(this.form.linkedCompany.name)
          await this.selectedCompanyLinkedInput(this.linkedCompanies[0])
          this.selectedLinkedCompany = this.linkedCompanies[0]
        }

        if (this.form.details.dateOfOrderExecution) {
          this.form.details.dateOfOrderExecution = moment(this.form.details.dateOfOrderExecution).format('YYYY-MM-DD')
        }
      } catch (e) {
      }
    },
    async uploadFiles (issueId) {
      try {
        const formData = new FormData()
        formData.append('issueId', issueId)
        for (let i = 0; i < this.form.details.documents.length; i++) {
          formData.append(`documents[${i}].fileWrapper.file`, this.form.details.documents[i])
          formData.append(`documents[${i}].description`, this.form.details.documents[i].name)
        }
        await Service.post('Issue/UploadDocuments', formData)
      } catch (e) {
      }
    },
    async submit () {
      await this.$refs.addIssueForm.validate()
      if (!this.valid) {
        return
      }
      this.saveLoading = true
      try {
        const { data } = await Service.post('Issue/CreateIssue', {
          ...this.form
        })

        if (this.form.details.documents && this.form.details.documents.length > 0) {
          await this.uploadFiles(data)
        }

        if (this.draftMode) {
          await Service.delete('Issue/DeleteIssueDraft', {
            issueDraftId: this.draftId
          })
        }

        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))

        await this.$router.push({
          name: 'IssuesCustomerService',
          params: { activeTab: '1' }
        })
      } catch (e) {
      } finally {
        this.saveLoading = false
      }
    },
    async uploadFileButtonInput (files) {
      this.form.details.documents = files
    },
    async uploadFileButtonRemove (file) {
      this.form.details.documents = this.form.details.documents.filter(f => f.name !== file.name)
    },
    async saveAsDraft () {
      if (this.saveLoading) {
        return
      }

      this.saveDraftLoading = true
      try {
        if (this.draftId) {
          await Service.post('Issue/UpdateIssueDraft', {
            ...this.form,
            issueDraftId: this.draftId
          })
        } else {
          const { data: draftId } = await Service.post('Issue/CreateIssueDraft', {
            ...this.form
          })
          this.draftId = draftId
        }
        this.lastVersion = cloneObj(this.form)
      } catch (e) {
      } finally {
        this.saveDraftLoading = false
      }
    },
    async autoSaveAsDraft () {
      if (equalsObj(this.lastVersion, this.form)) {
        return
      }
      await this.saveAsDraft()
      this.lastVersion = cloneObj(this.form)
    }
  }
}
</script>
